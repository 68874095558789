@import '../node_modules/swiper/swiper-bundle.css';
@import "../node_modules/swiper/components/pagination/pagination";
@import '../node_modules/@angular/cdk/overlay-prebuilt.css';

// text
@primary-text-color: #333333;
@secondary-text-color: #666666;
@tertiary-text-color: #999999;

@text-warning: #f6c343;
@text-success: #36AF65;
@text-processing: #1D64C4;
@text-cancel: #A6A6A6;
@text-danger: #e63757;

@blue-base: #1D64C4;
@blue-base-dark: #003781;
@blue-base-light: #2f74d0;

//@primary-blue-color: #2C7BE5;
@primary-blue-color: #364d79;
@secondary-blue-color: #6E84A3;
@primary-light-blue-color: #6E84A3;

@gray-text-color: #95AAC9;
@primary-blue-color: #2B66CC;
@primary-red-color: #e63757;
@primary-green-color: #36AF65;
@light-gray-color: #e8eaf0;
@menu-hover-color: #13263f;
@line-color: #E2EBF6;
@light-line-color: #EDF2F9;

@background-color: #FAFBFD;
@border-color: #E5EDF7;

@footer-height: 60px;
@menu-height: 72px;
@border-radius: 8px;

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  line-height: 1.5;
  margin: 0;
  font-family: Quicksand-Medium, PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, Hiragino Sans GB, Roboto, sans-serif !important
}

input::-webkit-input-placeholder {
  color: #DDDDDD;
}

/* 定义闪烁动画 */
@keyframes blink {
  0% { opacity: 1; } /* 开始时完全不透明 */
  50% { opacity: 0; } /* 中间时完全透明（即消失） */
  100% { opacity: 1; } /* 结束时再次完全不透明 */
}

/* 应用到红点元素 */
.red-dot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%; /* 使元素成为圆形 */
  animation: blink 0.6s linear infinite; /* 应用动画，持续0.6秒，线性变化，无限循环 */
  position: absolute;
  top: -4px;
  right: -4px;
}

thead {
  > tr {
    > th {
      font-weight: 500 !important;
      font-size: 14px;
    }
  }
}

tbody {
  > tr {
    > td {
      color: @primary-text-color;
      font-size: 14px;
    }
  }
}

//tr th {
//  font-weight: normal !important;
//  font-size: 11px;
//}

//nz-tab {
//  height: 100%;
//  position: static;
//}

.ant-tabs {
  height: 100%;
  position: static;
}

// 标签页底部
.ant-tabs-content {
  padding-bottom: @footer-height;
}

.centre-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centre-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.centre-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inner-content {
  height: 100%;
  margin: 0 30px 30px 30px;
}

.list-header-title {
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 18px;
  font-weight: 450;
  color: #333333;
}

.text-warning {
  color: @text-warning;
}

.text-success {
  color: @text-success;
}

.text-cancel {
  color: @text-cancel;
}

.text-processing {
  color: @text-processing;
}

.text-danger {
  color: @text-danger;
}

.functions {
  > a {
    color: black;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.property-row {
  padding: 5px 0 5px 0;
  display: flex;
  align-items: center;
  //justify-content: center;
  flex-direction: row;
  min-height: 46px;
}

.property-column {
  padding: 15px 0 0px 0;
  display: flex;
  align-items: flex-start;
  //justify-content: center;
  flex-direction: column;
}

.property-disable {
  opacity: 0.8;
}

.property-title-row {
  color: @tertiary-text-color;
  //color: @gray-text-color;
  font-size: 14px;
  font-weight: normal;
}

.property-value-row {
  padding: 4px 0 4px 4px;
  color: @primary-text-color;
  font-size: 14px;
  font-weight: normal;
  flex: 1;
  text-align: end;
  min-width: 80px;
}

.property-title-column {
  color: @tertiary-text-color;
  //color: @gray-text-color;
  font-size: 14px;
  font-weight: normal;
}

.property-value-column {
  padding: 4px 0 0 0;
  width: 100%;
  color: @primary-text-color;
  font-size: 14px;
  font-weight: normal;
  flex: 1;
  min-height: 32px;
}

.property-tip {
  color: @blue-base-light;
  font-size: 9px;
  margin-bottom: 5px;
}

.property-bottom-line {
  //border-bottom: 1px solid #EFEFEF;
  border-bottom: 1px solid @line-color;
}

.property-bottom-line-blue {
  border-bottom: 1px solid @line-color;
}

.property-pick-value {
  display: flex;
  align-items: center;
  flex-direction: row;
  //color: @primary-text-color;
  cursor: pointer;
  padding: 0 5px 0 5px;
}

.drawer-container {
  padding: 32px 32px calc(@footer-height + 10px) 32px;
}

.container {
  padding: 32px 0px calc(@footer-height + 10px) 0px;
}

.common-title {
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 25px;
}

.common-title-sm {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.drawer-title {
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 25px;
}

.drawer-title-small {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: @footer-height;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 14px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}


.footer-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: white;

  > a {
    color: @blue-base-dark;
  }

  > em {
    color: @blue-base-dark;
  }
}

.footer-button-primary {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, @blue-base-dark, @blue-base);

  > a {
    color: white;

    span {
      color: white;
    }

    em {
      color: white;
    }
  }

  > em {
    color: white;
  }

  * {
    color: white;
  }

}

.section-wrapper {
  margin-top: 20px;
  padding: 0 15px 0 15px;
  border: 1px solid @light-line-color;
  border-radius: @border-radius;
}

.section-title {
  width: 100%;
  display: block;
  border-left: 4px solid @blue-base;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin: 0 0 5px;
}

.sub-section-title {
  width: 100%;
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #333333;
  margin: 15px 0 5px;
}

.attachment {
  margin: 10px 0 0 0;
  padding: 10px 15px 10px 15px;
  border: 1px solid @light-line-color;
  border-radius: @border-radius;
  display: flex;
  flex-direction: row;
  align-items: center
}

.attachment-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin: 0 10px 0 10px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.tag {
  background-color: #1D64C4;
  color: white;
  border-radius: 4px;
  padding: 4px 6px 4px 6px;
  margin: 2px 2px 2px 2px;
  font-size: 13px;
}

.tag:first-child {
  margin: 2px 2px 2px 0;
}

.tag-sm {
  background-color: #1D64C4;
  color: white;
  border-radius: 4px;
  padding: 4px 6px 4px 6px;
  margin: 2px 2px 2px 2px;
  font-size: 11px;
}

.tag-sm:first-child {
  margin: 2px 2px 2px 0;
}

.tag-outline {
  color: #1D64C4;
  border: 1px solid #1D64C4;
  border-radius: 4px;
  padding: 4px 6px 4px 6px;
  margin: 2px 2px 2px 2px;
  font-size: 13px;
}

.tag-outline:first-child {
  margin: 2px 2px 2px 0;
}

.tag-outline-sm {
  color: #1D64C4;
  border: 1px solid #1D64C4;
  border-radius: 4px;
  padding: 2px 4px 2px 4px;
  margin: 2px 2px 2px 2px;
  font-size: 11px;
}

.tag-outline-sm:first-child {
  margin: 2px 2px 2px 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-row-space {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ant-input[disabled],.ant-input-number-disabled{
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.8;
}

.tip {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  border: 0.5px @light-line-color solid;
  border-radius: 6px;
}

.solid-box {
  margin-top: 15px;
  width: 100%;
  padding: 5px 20px 20px;
  border-radius: 12px;
  border: 1px solid #E5EDF7;
}
